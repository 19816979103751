// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_acciones.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import { SalWrapper } from "react-sal";
import faqjson from "../../misc/acciones_related/faq.json";
import slick_items from "../../misc/acciones_related/slick_items_acciones.json";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link} from "gatsby";

const pageTitle = 'Invertí en Acciones y hacé crecer tus ingresos | Balanz';
const pageDesc = 'Invertí en Acciones de forma rápida y segura. Conoce acá qué tipos de acciones que existen y toda la información necesaria para operar en Balanz.';
let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Acciones.</span>`;

const AccionesPage = () => {
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 8}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

  useEffect(() => {
      const handleXs = () => {
          const isXs = window.innerWidth <=  768;
          setXs(isXs);
      };

      window.addEventListener('resize', handleXs);

      handleXs();

      return () => {
          window.removeEventListener("resize", handleXs);
      };
  }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };

    useEffect(() => {
        apiRequest
            .get("/v1/faq/8")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])

  const page = (
    <>
        <Helmet>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué es una Acción?",
                        "description": "Una acción representa una porción del capital social de una empresa convirtiendo al inversor que la adquiere en accionista de la misma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=dG73wWHAWr4",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/acciones/",
                        "embedUrl": "https://balanz.com/inversiones/acciones/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Acciones?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=q7hTJH__1Vk",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/acciones/",
                        "embedUrl": "https://balanz.com/inversiones/acciones/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerAcciones"
          bannerTrasparecy="bannerMask"
          productTitle="Acciones"
          bannerImage={Banner}
          bannerImageClassName="accionesBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué es una Acción?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Una acción representa una porción del capital social de una empresa convirtiendo al inversor que la adquiere en accionista de la misma. Es para inversores agresivos o con experiencia, ya que está sujeta a una volatilidad media a alta dependiendo de diversos factores. Es uno de los instrumentos de inversión a largo plazo más conocidos del mercado, también se lo conoce como Renta Variable.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button variant="secondary" text="Invertir en Acciones" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                    <Col xs={12} >
                        <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Para acceder a acciones de una empresa es imprescindible que se encuentre en la Oferta Pública y esté autorizada para ser listada. En Argentina las acciones se negocian en la Bolsa de Comercio de Buenos Aires. Las principales acciones argentinas integran el índice S&P MERVAL.<br/>
                            La liquidación de las mismas se encuentra garantizada por BYMA como contraparte central.
                        </p>
                    </Col>
                </Row>
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/dG73wWHAWr4"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-5">
                    <Col xs={12}>
                        <p className="product-text">
                            El Precio al que se negocian es el resultado de la oferta y la demanda, dada por el volumen a operar. A mayor cantidad de compradores, sube el precio, mientras que a mayor cantidad de vendedores, baja el precio.<br/><br/>
                            Se denomina Renta Variable porque el inversor de acciones puede obtener ganancia o pérdida de capital según sea la variación en el precio de la acción entre el momento de compra y venta de la misma. Adicionalmente, el inversor puede obtener ganancias a través del cobro de dividendos; que son las ganancias que percibe la empresa emisora por su operación y las distribuye entre sus accionistas.

                        </p>
                    </Col>
                </Row>
                {/*<Row>
                    <div className="text-center pt-4 p-lg-3">
                        <Button variant="secondary" text="Ver Acciones"/>
                    </div>
                </Row>*/}
            </Container>
        </section>
        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 razones para invertir en Acciones</h2>
                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Acciones?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en Acciones" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>
                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/q7hTJH__1Vk"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Acciones? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'acciones-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"acciones"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default AccionesPage;
